<template>

    <div id="app" class="app-wrap" >
        <!-- app player container -->
        <main id="player-wrap" class="player-wrap" style="opacity: 0;" >
            <!-- bg absolute elements -->
            <figure id="player-bg" class="player-bg" v-bind:style="{ 'background-image' : `url(  ${ PochetteEnCours} )` }"></figure>
            <!-- main player layout -->
            <section class="player-layout" v-if="init">
                <!-- player top header -->
                <header class="player-header flex-row flex-middle flex-stretch">
                    <h2 class="text-clip flex-1">
                      <router-link to="/" custom v-slot="{ navigate }" >
                        <img :src="config.logo" @click="navigate" @keypress.enter="navigate" width="15%">
                        <!-- <span @click="navigate" @keypress.enter="navigate" role="link"> {{ config.title }}</span>-->
                      </router-link>
                    </h2>
                    <button class="text-nowrap common-btn" id="sidebar" @click="toggleSidebar( true )"><i
                            class="fa fa-bars" aria-label="sidebar"></i></button>
                </header>
                 <router-view :ArtisteEnCours="ArtisteEnCours" :TitreEnCours="TitreEnCours" :PochetteEnCours="PochetteEnCours" :config="config"> </router-view>

                 <footer-player :loading="loading" :playing="playing"
                   v-on:togglePlay="togglePlay" :config="config"></footer-player>

            </section> <!-- layout wrapper -->
            <!-- player stations overlay + sidebar -->
            <section class="player-stations"
                     :class="{ 'visible': sidebar }"
                     @click="toggleSidebar( false )"
            >
                <aside class="player-stations-sidebar" @click.stop>
                    <!-- sidebar search -->
                    <header class="player-stations-header flex-row flex-middle flex-stretch">
                        <div class="form-input push-right">
                        </div>
                        <button class="common-btn" @click="toggleSidebar( false )"><i
                                class="fa fa-times-circle" aria-label="Closesidebar"></i>
                        </button>
                    </header>
<br>
                    <router-link to="/" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Accueil</span>
                      </router-link><br>

                      <!--<router-link to="/station" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> test</span>
                      </router-link>

                      <router-link to="/dedicace" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Dedicaces</span>
                      </router-link>-->

                      <router-link to="/derniers-titres" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Derniers Titres</span>
                      </router-link><br>

                      <router-link to="/share" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Partager</span>
                      </router-link><br>

                    
                </aside>
            </section>

        </main> <!-- player -->

            
    </div>
</template>

<script>
import axios from 'axios';
    import _audio from './js/audio';
    import './scss/app.scss';
    import './js/filters';

    import { mapGetters, mapState  } from 'vuex';
    import { debounce } from "debounce";
    import footerPlayer from '@/views/station/components/footerPlayer'
    


    export default {
        name: 'home',
        components: {
    footerPlayer,
  },
        data: () => {
            return {
                // toggles
                init: false,
                visible: false,
                loading: true,
                sidebar: false,
                errors: {},
                playing: false,
                volume: 1,
                ArtisteEnCours: "",
                TitreEnCours: "",
                PochetteEnCours : "",
            }
        },
          // watch methods
  watch: {
    $route() {
                this.toggleSidebar();
            },
  },
        methods: {
          test() {
    axios.get(this.config.api_url)
    .then(response => {
    if (response.data.artist !== this.ArtisteEnCours) {
          this.ArtisteEnCours = response.data.artist;
          this.TitreEnCours = response.data.title;
          if(response.data.pochette) {
            this.PochetteEnCours = response.data.pochette;
          }   else {
             this.PochetteEnCours = "img/icon.png" ;
          }
          document.title = this.config.title + ": " + response.data.artist + " " + response.data.title;
          this.updateMediaSession(response.data.artist, response.data.title, response.data.pochette);
    }
    
    })
    setTimeout(this.test, 12000); 
},

    updateMediaSession(Artiste, Titre, Pochette) {
      console.log("%c updateMediaSession", 'color: green', Artiste)
      if ('mediaSession' in navigator && !!Artiste) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: Artiste,
          artist: Titre,
          artwork: [
            {src: Pochette}
          ]
        });
      }
    },
            initView() {
                console.log("init View");

                this.init = true;
                document.querySelector('#_spnr').style.display = 'none';
                document.querySelector('#player-wrap').style.opacity = '1';
                document.addEventListener('visibilitychange', e => {
                    this.visible = (document.visibilityState === 'visible')
                });


            },
            setError(key, err) {
                let errors = Object.assign({}, this.errors);
                errors[key] = String(err || '').trim();
                if (err) console.warn('ERROR(' + key + '):', err);
                this.errors = errors;
            },
            toggleSidebar(toggle) {
                this.sidebar = (typeof toggle === 'boolean') ? toggle : false;
            },
            onKeyboard(e) {
                const k = e.key || '';
                if (k === 'Enter') return this.toggleSidebar(true);
                if (k === 'Escape') return this.toggleSidebar(false);
            },
                clearErrors() {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = '';
      });
    },

            initPlayer() {
      window.addEventListener('keydown', this.onKeyboard);
      this.visible = true;
      //document.title = this.station.name +" - "+ this.config.title
    },

    // reset selected channel
    resetPlayer() {
      this.visible = false
      this.closeAudio();
      this.clearErrors();
    },


    // try resuming stream problem if possible
    tryAgain() {
      this.clearErrors();
      this.playChannel();
    },
    // toggle stream playback for current selected channel
    togglePlay() {
      if (this.loading) return;
      if (this.playing) return this.closeAudio();
      return this.playChannel();
    },

    // setup audio routing and stream events
    setupAudio() {
      const a = _audio.setupAudio();

      a.addEventListener('waiting', e => {
        this.playing = false;
        this.loading = true;
      });
      a.addEventListener('playing', e => {
        this.setError('stream', '');
        this.playing = true;
        this.loading = false;
      });
      a.addEventListener('ended', e => {
        this.playing = false;
        this.loading = false;
      });
      a.addEventListener('error', e => {
        this.closeAudio();
        console.log(e);
        this.setError('stream', `The selected stream (${this.station.name}) could not load, or has stopped loading due to a network problem.`);
        console.log('Error AUDIPOOOOOOOOOOOOOOOOOOO');
        this.playing = false;
        this.loading = false;
      });
    },

   // close active audio
    closeAudio() {
      _audio.stopAudio();
      this.playing = false;
    },

    // play audio stream for a channel
    playChannel() {

      this.loading = false;
      this.clearErrors();
     
    console.log("Je joue le son")
      //if(!!this.station)
      _audio.playSource(this.config.URL_Audio);
      console.log("Je joue le son")
      _audio.setVolume(this.volume);
    },


    Play() {
      this.closeAudio();
      this.initPlayer();
      this.playChannel();
    },

    
    // on keyboard events
    onKeyboard(e) {
      const k = e.key || '';
      if (k === ' ') return this.togglePlay();
    },

        },
        computed: {
            config(){
              return this.$store.getters["playerConfig/getConfig"];
            },

        },
        // on app mounted
        mounted() {
            console.log("App : mounted");
            this.$store.dispatch('playerConfig/fetchConfig').then(()=>{
              document.title = this.config.title
            });
            this.$store.dispatch('nowplaying/fetchNowplaying').then(()=>{
                this.initView();
            });
                this.setupAudio();
    this.Play();
    this.test();

    



        },

        // on app destroyed
        destroyed() {

        },

    beforeDestroy() {
    this.closeAudio();
  },

    }

</script>