import Vue from 'vue'
import Router from 'vue-router'

const Station = () => import('./views/station/Station')
const Home =  () => import('./views/Home')
const Dedicace =  () => import('./views/Dedicace')
const Share =  () => import('./views/Share')
const Derniers_titres =  () => import('./views/station/components/songsHistory')

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/station',
      name: 'station',
      component: Station
    },
    {
      path: '/dedicace',
      name: 'dedicace',
      component: Dedicace
    },
    {
      path: '/share',
      name: 'share',
      component: Share
    },
    {
      path: '/derniers-titres',
      name: 'derniers_titres',
      component: Derniers_titres
    }
  ]
})
